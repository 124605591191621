import $ from 'jquery';

import { BACKEND_API } from './constants';

const postToContractorBack = (data) => $.ajax({
  method: 'POST',
  url: `${BACKEND_API}/backend/functions/contractorBack/contractorBack.php`,
  data,
});

export const searchContractorWorkOrders = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'searchContractorWorkOrders',
    ...formData,
    UTILITYID: 15,
  };
  return postToContractorBack(data);
};

export const viewPDF = (sessionFromReact, WorkOrderID) => {
  const data = {
    sessionFromReact,
    action: 'viewPDF',
    WorkOrderID,
  };
  return postToContractorBack(data);
};

export const getWorkOrderHelper = (sessionFromReact, WorkOrderID, endPoint) => {
  const data = {
    sessionFromReact,
    action: 'getWorkOrderHelper',
    WorkOrderID,
    endPoint,
  };
  return postToContractorBack(data);
};

export const viewAttachment = (sessionFromReact, WorkOrderID, nCount) => {
  const data = {
    sessionFromReact,
    action: 'viewAttachment',
    WorkOrderID,
    nCount,
  };
  return postToContractorBack(data);
};

export const getFiberDropTypesWorkOrders = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getFiberDropTypesWorkOrders',
  };
  return postToContractorBack(data);
};

export const updateCheckLists = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'updateCheckLists',
    UtilityID: 1, // TODO: it's 15 for installer template call, check the value in fiber templates
    ...formData,
  };
  return postToContractorBack(data);
};

export const getFiberConstructionWorkOrderDetails = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getFiberConstructionWorkOrderDetails',
    UtilityID: 1,
    ...formData,
  };
  return postToContractorBack(data);
};

export const getFiberCoiledWorkOrderDetails = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getFiberCoiledWorkOrderDetails',
    UtilityID: 1,
    ...formData,
  };
  return postToContractorBack(data);
};

export const getFiberInstallWorkOrderDetails = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getFiberInstallWorkOrderDetails',
    UtilityID: 1,
    ...formData,
  };
  return postToContractorBack(data);
};

export const getFiberYardComplaintWorkOrderDetails = (sessionFromReact, nCount) => {
  const data = {
    sessionFromReact,
    action: 'getFiberYardComplaintWorkOrderDetails',
    UtilityID: 1,
    nCount,
  };
  return postToContractorBack(data);
};

export const getFiberSiteSurveyWorkOrderDetails = (sessionFromReact, nCount, type) => {
  const data = {
    sessionFromReact,
    action: 'getFiberSiteSurveyWorkOrderDetails',
    nCount,
    type,
    UtilityID: 1,
  };
  return postToContractorBack(data);
};

export const getFiberSplicingWorkOrderDetails = (sessionFromReact, nCount, type) => {
  const data = {
    sessionFromReact,
    action: 'getFiberSplicingWorkOrderDetails',
    nCount,
    type,
    UtilityID: 1,
  };
  return postToContractorBack(data);
};

export const getFiberConnectorizedInstallWorkOrderDetails = (sessionFromReact, nCount) => {
  const data = {
    sessionFromReact,
    action: 'getFiberConnectorizedInstallWorkOrderDetails',
    nCount,
    UtilityID: 1,
  };
  return postToContractorBack(data);
};

export const getSpliceCaseTypes = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getSpliceCaseTypes',
    UtilityID: 1,
  };
  return postToContractorBack(data);
};

export const getFiberWorkOrderDetailsBaseHelper = (sessionFromReact, nCount, templateType) => {
  const data = {
    sessionFromReact,
    action: 'getFiberWorkOrderDetailsBaseHelper',
    nCount,
    templateType,
    UtilityID: 1,
  };
  return postToContractorBack(data);
};

export const addWorkOrderAssemblyUnit = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'addWorkOrderAssemblyUnit',
    ...formData,
    UtilityID: 1,
  };
  return postToContractorBack(data);
};

// sending formData will be treated differently from other requests due to the file
export const uploadAttachment = (data) => $.ajax({
  url: `${BACKEND_API}/backend/functions/contractorBack/contractorBack.php`,
  method: 'POST',
  success: (response) => response,
  error: (error) => error,
  data,
  processData: false,
  contentType: false,
});

export const getFiberContracts = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getFiberContracts',
    UtilityID: 1,
  };
  return postToContractorBack(data);
};

export const getCUPSheet = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getCUPSheet',
    ...formData,
    UtilityID: 1,
  };
  return postToContractorBack(data);
};

export const getCalendarEvents = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getCalendarEvents',
    UtilityID: 1,
  };
  return postToContractorBack(data);
};

export const getMapData = (sessionFromReact, mapType) => {
  const data = {
    sessionFromReact,
    action: 'getMapData',
    mapType,
    UtilityID: 22,
  };
  return postToContractorBack(data);
};

export const getContractors = (sessionFromReact, mapType) => {
  const data = {
    sessionFromReact,
    action: 'getContractors',
    mapType,
    UtilityID: 22,
  };
  return postToContractorBack(data);
};

export const getWorkorderAcknowledge = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getWorkorderAcknowledge',
    UtilityID: 22,
  };
  return postToContractorBack(data);
};

export const updateAcknowledgeWorkOrder = (sessionFromReact, WorkOrderID, ack) => {
  const data = {
    sessionFromReact,
    action: 'updateAcknowledgeWorkOrder',
    WorkOrderID,
    ack,
    UtilityID: 22,
  };
  return postToContractorBack(data);
};

export const queryLastWOSearch = (sessionFromReact, searchResult) => {
  const data = {
    sessionFromReact,
    action: 'queryLastWOSearch',
    searchResult,
    UtilityID: 22,
  };
  return postToContractorBack(data);
};

export const selectRestorationTickets = (sessionFromReact, ContractorID) => {
  const data = {
    sessionFromReact,
    action: 'selectRestorationTickets',
    ContractorID,
    UtilityID: 22,
  };
  return postToContractorBack(data);
};

export const addRestorationTicketNote = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'addRestorationTicketNote',
    ...formData,
    UtilityID: 22,
  };
  return postToContractorBack(data);
};

export const viewTroubleTicketImage = (sessionFromReact, nCount) => {
  const data = {
    sessionFromReact,
    action: 'viewTroubleTicketImage',
    nCount,
    UtilityID: 22,
  };
  return postToContractorBack(data);
};

export const getGISObjectIds = (sessionFromReact, payload) => {
  const data = {
    sessionFromReact,
    action: 'getGISObjectIds',
    payload,
    UtilityID: 22,
  };
  return postToContractorBack(data);
};
