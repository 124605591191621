import $ from 'jquery';
// import { cacheResponseHelper } from './helpers';
import { ARCGIS_ENDPOINT_URL } from './constants';

export const createArcGISToken = async () => {
  const username = 'provapp';
  const password = 'a59277f4d3';
  const referer = window.location.origin;
  const client = 'referer';
  const expiration = 60 * 60;
  const data = {
    username,
    password,
    client,
    referer,
    expiration,
  };

  let token = '';
  await $.ajax({
    url: `${ARCGIS_ENDPOINT_URL}/arcgis/tokens/`,
    method: 'POST',
    data,
    success: (t) => { token = t; },
    error: (error) => { console.log(error); },
  });
  return token;
};

export function handleGISObjectLlink({ vcViewFile = '', objectId = '' }) {
  if (!vcViewFile || !objectId) {
    return '';
  }
  switch (vcViewFile) {
    case '/fiber/fiberbackbonesplice.php':
    case '/fiber/splicing.php':
      return `https://socket.maps.arcgis.com/apps/webappviewer/index.html?id=188a7f19f7754090be0e8d8961c095bd&query=190c623db44-layer-45%2COBJECTID%2C${objectId}`;
    case '/fiber/connectorizedinstall.php':
      return `https://socket.maps.arcgis.com/apps/webappviewer/index.html?id=188a7f19f7754090be0e8d8961c095bd&query=190c623db44-layer-44%2COBJECTID%2C${objectId}`;
    default:
      return '';
  }
}
