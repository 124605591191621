import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  updateCheckLists,
  getFiberSiteSurveyWorkOrderDetails,
  logout,
  handleMessage,
} from '../../../../utils';
import { disableInputs } from '../../utils';
import { preSubmitChecker } from '../functions';

function SiteSurvey({ WorkOrderID, closeEditModal, type }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [arrivalDate, setArrivalDate] = useState('');
  const [arrivalTime, setArrivalTime] = useState('00:00');
  const [completedDate, setCompletedDate] = useState('');
  const [completedTime, setCompletedTime] = useState('00:00');
  const [secNum, setSecNum] = useState('');
  const [stakingSheetNum, setStakingSheetNum] = useState('');
  const [ONT, setONT] = useState('');
  const [invisibleFence, setInvisibleFence] = useState('unknown');
  const [sprinklerSystem, setSprinklerSystem] = useState('unknown');
  const [septicSystem, setSepticSystem] = useState('unknown');
  const [fencedYard, setFencedYard] = useState('unknown');
  const [yardLights, setYardLights] = useState('unknown');
  const [lpGas, setLpGas] = useState('unknown');
  const [privatePower, setPrivatePower] = useState('unknown');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [WOComplete, setWOComplete] = useState(false);

  const handleSubmit = () => {
    handleMessage('', 'Modal');
    const formData = {};
    formData.WorkOrderID = WorkOrderID;
    formData.ArrivalOnsite = arrivalDate;
    formData.CompletedDate = completedDate;
    formData.SecNum = secNum;
    formData.StakingSheetNum = stakingSheetNum;
    formData.ONT = ONT;
    formData.InvisibleFence = invisibleFence;
    formData.Sprinkler = sprinklerSystem;
    formData.SepticSys = septicSystem;
    formData.FencedYard = fencedYard;
    formData.YardLights = yardLights;
    formData.LPGas = lpGas;
    formData.PrivatePower = privatePower;
    formData.AdditionalNotes = additionalNotes;
    formData.WOComplete = WOComplete ? 'on' : 'off';
    updateCheckLists(sessionFromReact, formData)
      .then((response) => {
        if (response && response.toLowerCase().includes('error')) {
          handleMessage(response, 'Modal');
        } else {
          handleMessage('Updated successfully', 'Modal');
          closeEditModal();
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
  };
  useEffect(() => {
    let isMounted = true;
    getFiberSiteSurveyWorkOrderDetails(sessionFromReact, WorkOrderID, type)
      .then((response) => {
        if (!response || !isMounted) {
          return;
        }
        const parsed = JSON.parse(response);
        const {
          tsArrivalOnsite,
          tsWorkComplete,
          vcSecNum,
          vcStakingSheetNum,
          vcONT,
          nInvisibleFence,
          nSprinkler,
          nSepticSys,
          nFencedYard,
          nYardLights,
          nLPGas,
          nPrivatePower,
          txtNotes,
          vcStatus,
        } = parsed;
        const [date, time] = (tsArrivalOnsite || '').split(' ');
        const [completeDate, completeTime] = (tsWorkComplete || '').split(' ');
        setArrivalDate(date || '');
        setArrivalTime(time || '00:00');
        setCompletedDate(completeDate || '');
        setCompletedTime(completeTime || '00:00');
        setSecNum(vcSecNum || '');
        setStakingSheetNum(vcStakingSheetNum || '');
        setONT(vcONT || '');
        setInvisibleFence(nInvisibleFence || '');
        setSprinklerSystem(nSprinkler || '');
        setSepticSystem(nSepticSys || '');
        setFencedYard(nFencedYard || '');
        setYardLights(nYardLights || '');
        setLpGas(nLPGas || '');
        setPrivatePower(nPrivatePower || '');
        setAdditionalNotes(txtNotes || '');
        const completed = vcStatus === 'Complete';
        setWOComplete(completed);
        disableInputs(completed);
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
    return () => { isMounted = false; };
  }, [WorkOrderID]);

  const render3RadioButtons = (label, variable, variableName, setter) => (
    <tr className="dataBlock">
      <td className="centerContent"><p>{label}</p></td>
      <td className="centerContent">
        <label htmlFor={`${variableName}Yes`}>
          <input id={`${variableName}Yes`} type="radio" name={variableName} onChange={() => setter('1')} checked={variable === '1'} />
          Yes
        </label>
        <label htmlFor={`${variableName}No`}>
          <input id={`${variableName}No`} type="radio" name={variableName} onChange={() => setter('2')} checked={variable === '2'} />
          No
        </label>
        <label htmlFor={`${variableName}Unknown`}>
          <input id={`${variableName}Unknown`} type="radio" name={variableName} onChange={() => setter('0')} checked={variable === '0'} />
          Unknown
        </label>
      </td>
    </tr>
  );

  return (
    <table className="editWOForm darkergray">
      <tbody>
        <tr>
          <th colSpan={2} className="title"><h3 className="centerContent">Onsite Checklist - Site Survey</h3></th>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Date/Time of Arrival Onsite:</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={arrivalDate} onChange={(e) => setArrivalDate(e.target.value)} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={arrivalTime} onChange={(e) => setArrivalTime(e.target.value)} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Date/Time of Work Completed</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={completedDate} onChange={(e) => setCompletedDate(e.target.value)} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={completedTime} onChange={(e) => setCompletedTime(e.target.value)} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Set Num:</p></td>
          <td className="centerContent">
            <input
              value={stakingSheetNum}
              onChange={(e) => setSecNum(e.target.value)}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Staking Sheet Num:</p></td>
          <td className="centerContent">
            <input
              value={stakingSheetNum}
              onChange={(e) => setStakingSheetNum(e.target.value)}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>ONT</p></td>
          <td className="centerContent">
            <label htmlFor="ONTIndoor">
              <input id="ONTIndoor" type="radio" name="ONT" onChange={() => setONT('Indoor')} checked={ONT === 'Indoor'} />
              Indoor
            </label>
            <label htmlFor="ONTOutdoor">
              <input id="ONTOutdoor" type="radio" name="ONT" onChange={() => setONT('Outdoor')} checked={ONT === 'Outdoor'} />
              No
            </label>
          </td>
        </tr>
        {render3RadioButtons('Invisible Fence', invisibleFence, 'invisibleFence', setInvisibleFence)}
        {render3RadioButtons('Sprinkler System', sprinklerSystem, 'sprinklerSystem', setSprinklerSystem)}
        {render3RadioButtons('Septic System', septicSystem, 'septicSystem', setSepticSystem)}
        {render3RadioButtons('Fenced Yard', fencedYard, 'fencedYard', setFencedYard)}
        {render3RadioButtons('Yard Lights', yardLights, 'yardLights', setYardLights)}
        {render3RadioButtons('LP Gas', lpGas, 'lpGas', setLpGas)}
        <tr className="dataBlock">
          <td className="centerContent"><p>Additional Notes:</p></td>
          <td className="centerContent">
            <textarea
              className="textBlockSize"
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
            />
          </td>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="complete">Complete Work Order?</label>
            <input id="complete" type="checkbox" checked={WOComplete} onChange={() => setWOComplete(!WOComplete)} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="update-div centerContent" colSpan={2}>
            <button type="button" onClick={() => preSubmitChecker(WOComplete, handleSubmit)}>Update Checklist</button>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div id="msgDivModal" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default SiteSurvey;
