import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import {
  updateCheckLists,
  getFiberSplicingWorkOrderDetails,
  getSpliceCaseTypes,
  logout,
  handleMessage,
} from '../../../../utils';
import { disableInputs } from '../../utils';
import { preSubmitChecker } from '../functions';

const defaultFormData = {
  arrivalDate: '',
  arrivalTime: '00:00',
  completedDate: '',
  completedTime: '00:00',
  handHoleAddress: '',
  spliceCaseTypes: [],
  spliceCaseType: '',
  auditingSpliceCase: '',
  spliceCaseRehung: false,
  singleFusionSpliceQty: 0,
  ribbonSpliceQty: 0,
  ribbonizedCablesQty: 0,
  additionalNotes: '',
  lightWhenFinishedSplicing: '',
  WOComplete: false,
};

function Splicing({ WorkOrderID, closeEditModal, type }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [formData, setFormData] = useState(defaultFormData);
  const {
    arrivalDate,
    arrivalTime,
    completedDate,
    completedTime,
    handHoleAddress,
    spliceCaseTypes,
    spliceCaseType,
    auditingSpliceCase,
    spliceCaseRehung,
    singleFusionSpliceQty,
    ribbonSpliceQty,
    ribbonizedCablesQty,
    additionalNotes,
    lightWhenFinishedSplicing,
    WOComplete,
  } = formData;

  const handleSubmit = () => {
    handleMessage('', 'Modal');
    const payLoad = {};
    if (lightWhenFinishedSplicing === '') {
      Swal.fire({
        icon: 'error',
        text: 'Please select a value for "Was there Light when you finished Splicing?" dropdown',
      });
      return;
    }
    payLoad.WorkOrderID = WorkOrderID;
    payLoad.ArrivalOnsite = arrivalDate;
    payLoad.ArrivalTime = arrivalTime;
    payLoad.CompletedDate = completedDate;
    payLoad.CompletedTime = completedTime;
    payLoad.HandHoleAddress = handHoleAddress;
    payLoad.vcSpliceCaseType = spliceCaseType;
    payLoad.nAuditSpliceCase = auditingSpliceCase;
    payLoad.nSingleFusionSpliceQty = singleFusionSpliceQty;
    payLoad.nRibbonSpliceQty = ribbonSpliceQty;
    payLoad.nRibbonizedCablesQty = ribbonizedCablesQty;
    payLoad.AdditionalNotes = additionalNotes;
    payLoad.nSpawnRehang = spliceCaseRehung;
    payLoad.nLightWhenFinishedSplicing = lightWhenFinishedSplicing;
    payLoad.WOComplete = WOComplete ? 'on' : 'off';

    updateCheckLists(sessionFromReact, payLoad)
      .then((response) => {
        if (response && response.toLowerCase().includes('error')) {
          handleMessage(response, 'Modal');
        } else {
          handleMessage('Updated successfully', 'Modal');
          closeEditModal();
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
  };

  const renderSpliceCaseType = () => {
    const options = spliceCaseTypes.map((obj) => {
      const { vcType } = obj;
      return (<option key={`${vcType}Option`} value={vcType}>{vcType}</option>);
    });

    return (
      <tr className="dataBlock">
        <th>
          <p className="centerContent">
            Splice Case Type:
          </p>
        </th>
        <td className="centerContent">
          <select value={spliceCaseType} onChange={(e) => setFormData({ ...formData, spliceCaseType: e.target.value })}>
            <option value="">Select One</option>
            {options}
          </select>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    getSpliceCaseTypes(sessionFromReact)
      .then((response) => {
        setFormData({ ...formData, spliceCaseTypes: JSON.parse(response) });
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
  }, []);
  useEffect(() => {
    let isMounted = true;
    if (formData.spliceCaseTypes.length === 0) {
      return () => { isMounted = false; };
    }
    getFiberSplicingWorkOrderDetails(sessionFromReact, WorkOrderID, type)
      .then((response) => {
        if (!response || !isMounted) {
          return;
        }
        const parsed = JSON.parse(response);
        const {
          tsArrivalOnsite,
          tsWorkComplete,
          vcHandHoleAddress,
          vcSpliceCaseType,
          nAuditSpliceCase,
          nSingleFusionSpliceQty,
          nRibbonSpliceQty,
          nRibbonizedCablesQty,
          txtNotes,
          vcStatus,
          nLightWhenFinishedSplicing,
        } = parsed;
        const [date, time] = (tsArrivalOnsite || '').split(' ');
        const [completeDate, completeTime] = (tsWorkComplete || '').split(' ');
        const newFormData = {
          ...formData,
          arrivalDate: date || '',
          arrivalTime: time || '00:00',
          completedDate: completeDate || '',
          completedTime: completeTime || '00:00',
          handHoleAddress: vcHandHoleAddress || '',
          spliceCaseType: vcSpliceCaseType || '',
          auditingSpliceCase: nAuditSpliceCase || '',
          singleFusionSpliceQty: nSingleFusionSpliceQty || 0,
          ribbonSpliceQty: nRibbonSpliceQty || 0,
          ribbonizedCablesQty: nRibbonizedCablesQty || 0,
          additionalNotes: txtNotes || '',
          lightWhenFinishedSplicing: nLightWhenFinishedSplicing || '',
          wOComplete: vcStatus === 'Complete',
        };
        disableInputs(vcStatus === 'Complete');
        setFormData(newFormData);
      })
      .catch((error) => {
        console.log(error);
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
    return () => { isMounted = false; };
  }, [WorkOrderID, formData.spliceCaseTypes.length]);

  return (
    <table className="editWOForm darkergray">
      <tbody>
        <tr>
          <th colSpan={2} className="title"><h3 className="centerContent">Onsite Checklist - Fiber Splicing</h3></th>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Date/Time of Arrival Onsite:</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={arrivalDate} onChange={(e) => setFormData({ ...formData, arrivalDate: e.target.value })} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={arrivalTime} onChange={(e) => setFormData({ ...formData, arrivalTime: e.target.value })} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Date/Time of Work Completed</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={completedDate} onChange={(e) => setFormData({ ...formData, completedDate: e.target.value })} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={completedTime} onChange={(e) => setFormData({ ...formData, completedTime: e.target.value })} />
          </td>
        </tr>
        <tr className="dataBlock">
          <th>
            <p className="centerContent">
              Was there Light when you finished Splicing?
            </p>
          </th>
          <td className="centerContent">
            <select
              value={lightWhenFinishedSplicing}
              onChange={(e) => setFormData({ ...formData, lightWhenFinishedSplicing: e.target.value })}
            >
              <option value="">Please Select a Value</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </td>
        </tr>
        <tr className="dataBlock">
          <th><p className="centerContent">Hand Hole Address:</p></th>
          <td className="centerContent">
            <input
              value={handHoleAddress}
              onChange={(e) => setFormData({ ...formData, handHoleAddress: e.target.value })}
            />
          </td>
        </tr>
        {renderSpliceCaseType()}
        <tr className="dataBlock">
          <th>
            <p className="centerContent">
              Auditing Splice Case?:
            </p>
          </th>
          <td className="centerContent">
            <select
              value={auditingSpliceCase}
              onChange={(e) => setFormData({ ...formData, auditingSpliceCase: e.target.value })}
            >
              <option value="">Please Select a Value</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </td>
        </tr>
        <tr className="dataBlock">
          <th><p className="centerContent">Single Fusion Splice Quantity:</p></th>
          <td className="centerContent">
            <input
              value={singleFusionSpliceQty}
              onChange={(e) => setFormData({ ...formData, singleFusionSpliceQty: e.target.value })}
              className="width10Percent"
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <th><p className="centerContent">Ribbon Splice Quantity:</p></th>
          <td className="centerContent">
            <input
              value={ribbonSpliceQty}
              onChange={(e) => setFormData({ ...formData, ribbonSpliceQty: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <th><p className="centerContent">Ribbonized Cables Splice Quantity:</p></th>
          <td className="centerContent">
            <input
              value={ribbonizedCablesQty}
              onChange={(e) => setFormData({ ...formData, ribbonizedCablesQty: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Additional Notes:</p></td>
          <td className="centerContent">
            <textarea
              className="textBlockSize"
              value={additionalNotes}
              onChange={(e) => setFormData({ ...formData, additionalNotes: e.target.value })}
            />
          </td>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="complete">Complete Work Order?</label>
            <input id="complete" type="checkbox" checked={WOComplete} onChange={() => setFormData({ ...formData, WOComplete: !WOComplete })} />
          </td>
        </tr>
        <tr />
        <tr className="dataBlock">
          <td className="centerContent">
            (One time option, not persisted in the checklist)
          </td>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="SpliceCaseRehung">
              Create Aerial Rehang WO?
            </label>
            <input id="SpliceCaseRehung" type="checkbox" checked={spliceCaseRehung} onChange={() => setFormData({ ...formData, spliceCaseRehung: !spliceCaseRehung })} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="update-div centerContent" colSpan={2}>
            <button type="button" onClick={() => preSubmitChecker(WOComplete, handleSubmit)}>Update Checklist</button>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div id="msgDivModal" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Splicing;
