import React from 'react';

import * as Templates from '../templates';

function CheckList({ WOObj = {}, closeEditModal = () => {} }) {
  if (!Object.keys(WOObj).length) {
    return null;
  }
  const WOId = WOObj.WorkOrderID;
  // all templates refer to inside/workorders/templates/*
  switch (WOObj.WorkOrderType?.vcViewFile) {
    case '/fiber/businessconstruction.php':
      return (
        <Templates.Fiber.Construction
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="business"
        />
      );
    case '/fiber/businessinstall.php':
      return (
        <Templates.Fiber.Install
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="business"
        />
      );
    case '/fiber/businesssitesurvey.php':
      return (
        <Templates.Fiber.SiteSurvey
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="business"
        />
      );
    case '/fiber/businesssplicing.php':
      return (
        <Templates.Fiber.Splicing
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="business"
        />
      );
    case '/fiber/businesstvinstall.php':
      return (
        <Templates.Fiber.TVInstall
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="business"
          templateType="BusinessTVInstall"
        />
      );
    case '/fiber/construction.php':
      return (
        <Templates.Fiber.Construction
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="consumer"
          title="Drop Construction"
        />
      );
    case '/fiber/burydrop.php':
      return (
        <Templates.Fiber.Construction
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="consumer"
          title="Bury Temp Drop"
          templateType="BuryTempDrop"
        />
      );
    case '/fiber/buryhybriddrop.php':
      return (
        <Templates.Fiber.Construction
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="consumer"
          title="Bury Hybrid Drop"
          templateType="BuryHybridDrop"
        />
      );
    case '/fiber/fiberbackbonesplice.php':
      return (
        <Templates.Fiber.Base
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="consumer"
          templateType="BackboneSplice"
          title="Backbone Splice"
        />
      );
    case '/fiber/install.php':
      return (
        <Templates.Fiber.Install
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="consumer"
          templateType="FiberInstall"
        />
      );
    case '/fiber/coileddrop.php':
      return (
        <Templates.Fiber.Coiled
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="consumer"
          templateType="FiberCoiledDrop"
        />
      );
    case '/fiber/sitesurvey.php':
      return (
        <Templates.Fiber.SiteSurvey
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="consumer"
        />
      );
    case '/fiber/splicefieldsplitter.php':
      return (
        <Templates.Fiber.Base
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="consumer"
          templateType="SpliceFieldSplitter"
          title="Fiber Splice Field Splitter"
        />
      );
    case 'hpbxsitesurvey.php':
      return (
        <Templates.Fiber.Base
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          templateType="HPBXSiteSurvey"
          title="HPBX Site Survey"
        />
      );
    case '/fiber/splicing.php':
      return (
        <Templates.Fiber.Splicing
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="consumer"
        />
      );
    case '/fiber/tvinstall.php':
      return (
        <Templates.Fiber.TVInstall
          closeEditModal={closeEditModal}
          WorkOrderID={WOId}
          type="consumer"
          templateType="TVInstall"
        />
      );
    case '/fiber/connectorizedinstall.php':
      return (
        <Templates.Fiber.Connectorized
          WorkOrderID={WOId}
          type="dispatch"
          closeEditModal={closeEditModal}
        />
      );
    case '/fiber/yardcomplaint.php':
      return (
        <Templates.Fiber.YardComplaint
          WorkOrderID={WOId}
          closeEditModal={closeEditModal}
        />
      );
    case '/install/residentialColoInstall.php': // WOType === '24'
      return (
        <Templates.Install.ColoInstall
          WorkOrderID={WOId}
          type="consumer"
          closeEditModal={closeEditModal}
        />
      );
    case '/install/businessColoInstall.php': // WOType === '25'
      return (
        <Templates.Install.ColoInstall
          WorkOrderID={WOId}
          type="business"
          closeEditModal={closeEditModal}
        />
      );
    case '/dispatch/dispatch.php':
      return (
        <Templates.Install.ColoInstall
          WorkOrderID={WOId}
          type="dispatch"
          closeEditModal={closeEditModal}
        />
      );
    default:
      return (
        <h1>
          Template not found:
          {WOObj.WorkOrderType?.vcViewFile}
        </h1>
      );
  }
}

export default CheckList;
